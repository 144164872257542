import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
}
export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1024.000000 1024.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1024.000000) scale(0.100000,-0.100000)">


<path d="M4900 9450 c-171 -10 -355 -29 -440 -45 -41 -7 -106 -19 -145 -25
-155 -26 -222 -38 -265 -48 -25 -6 -67 -16 -95 -22 -123 -28 -163 -38 -181
-47 -10 -6 -38 -13 -60 -17 -23 -3 -54 -13 -70 -21 -16 -8 -35 -15 -43 -15
-17 0 -133 -37 -178 -56 -17 -8 -34 -14 -39 -14 -4 0 -40 -16 -81 -35 -41 -19
-79 -35 -84 -35 -6 0 -23 -7 -37 -15 -37 -20 -219 -95 -231 -95 -5 0 -11 -4
-13 -8 -3 -10 -42 -31 -100 -57 -21 -10 -38 -21 -38 -26 0 -5 -9 -9 -19 -9
-11 0 -23 -5 -26 -10 -4 -6 -21 -17 -38 -25 -18 -8 -39 -19 -47 -24 -8 -6 -48
-29 -87 -52 -49 -27 -73 -47 -73 -60 0 -21 16 -34 25 -19 3 6 21 17 38 25 18
8 59 33 92 54 33 22 102 60 152 86 51 26 95 50 98 55 3 4 17 11 32 15 15 3 34
12 42 18 7 6 29 17 47 24 19 7 50 19 69 26 20 8 41 19 47 25 7 7 18 12 26 12
7 0 32 8 55 18 54 24 107 45 182 73 33 12 74 28 92 35 17 8 38 14 47 14 8 0
33 6 53 14 48 18 251 77 313 91 14 3 48 12 75 20 28 7 77 18 110 24 60 10 112
19 255 46 200 38 389 50 750 49 277 -1 378 -5 461 -17 59 -10 141 -17 182 -17
42 0 88 -5 104 -11 15 -6 62 -17 103 -24 41 -8 102 -19 135 -25 33 -6 75 -16
94 -22 19 -6 60 -16 90 -24 82 -19 142 -34 176 -46 17 -5 50 -14 75 -20 25 -5
59 -15 75 -23 17 -7 45 -16 63 -20 17 -4 32 -11 32 -16 0 -5 9 -9 21 -9 11 0
41 -9 67 -19 26 -11 79 -32 117 -46 133 -51 418 -186 540 -255 22 -13 60 -34
85 -48 25 -14 75 -43 113 -66 37 -22 83 -50 101 -61 19 -11 60 -38 92 -60 32
-22 63 -42 69 -45 26 -12 123 -82 153 -110 18 -16 36 -30 39 -30 26 0 466
-413 568 -533 22 -27 49 -58 60 -71 53 -60 72 -84 99 -121 16 -22 37 -47 45
-56 26 -27 44 -50 96 -129 27 -41 52 -77 55 -80 12 -11 153 -230 167 -260 7
-14 17 -32 22 -40 5 -8 12 -22 16 -30 3 -8 17 -33 30 -55 13 -22 51 -96 85
-165 34 -69 70 -142 81 -162 10 -20 19 -42 19 -49 0 -6 9 -30 19 -53 11 -23
29 -68 41 -101 12 -33 26 -68 31 -77 5 -10 13 -34 18 -55 11 -45 39 -126 57
-168 7 -16 15 -48 19 -70 3 -22 12 -58 19 -80 8 -22 23 -85 35 -140 12 -55 27
-118 32 -140 5 -22 14 -74 20 -115 5 -41 16 -106 24 -145 12 -62 40 -455 39
-560 -1 -93 -26 -455 -35 -515 -6 -36 -16 -110 -23 -165 -7 -55 -18 -110 -24
-122 -7 -12 -12 -37 -12 -56 0 -20 -9 -64 -20 -99 -11 -34 -20 -76 -20 -91 0
-15 -7 -41 -15 -56 -8 -16 -17 -48 -21 -72 -4 -24 -13 -53 -20 -64 -8 -11 -14
-28 -14 -37 0 -10 -8 -42 -18 -71 -25 -72 -14 -111 19 -71 13 16 38 92 60 179
5 22 14 49 19 60 12 25 37 127 55 225 7 41 18 89 24 105 10 32 26 123 42 245
5 41 17 123 25 182 23 153 23 694 0 828 -9 52 -16 120 -16 150 0 30 -9 88 -20
129 -11 41 -20 92 -20 113 0 22 -5 48 -11 59 -5 11 -16 52 -24 92 -8 40 -19
92 -26 117 -21 82 -50 185 -61 215 -5 17 -14 47 -19 69 -5 21 -16 51 -24 67
-8 15 -15 36 -15 46 0 10 -9 35 -20 56 -11 20 -20 42 -20 48 0 11 -32 88 -55
132 -8 16 -15 32 -15 37 0 10 -76 177 -90 200 -6 8 -15 26 -20 40 -10 24 -30
63 -77 145 -12 22 -29 54 -38 70 -16 31 -22 40 -69 115 -69 110 -126 192 -161
236 -14 18 -25 35 -25 38 0 3 -7 14 -15 23 -8 10 -41 54 -74 98 -54 73 -89
116 -146 180 -11 13 -35 41 -52 63 -43 52 -277 286 -339 339 -27 22 -61 52
-76 66 -15 14 -54 44 -87 67 -34 24 -61 47 -61 51 0 5 -6 9 -13 9 -7 0 -18 9
-25 20 -7 11 -19 20 -26 20 -8 0 -16 3 -18 8 -5 12 -76 62 -87 62 -6 0 -11 4
-11 9 0 5 -33 30 -72 57 -40 27 -86 61 -103 76 -16 16 -35 28 -41 28 -6 0 -31
15 -55 33 -24 18 -89 59 -144 91 -55 31 -107 61 -115 67 -8 5 -26 15 -40 21
-14 7 -27 15 -30 18 -5 6 -35 21 -165 80 -151 69 -173 79 -265 114 -52 21
-118 46 -145 57 -28 10 -58 19 -67 19 -9 0 -19 4 -22 9 -7 12 -192 67 -303 91
-23 5 -63 16 -90 23 -52 16 -125 31 -243 52 -41 7 -93 18 -115 24 -22 6 -56
11 -75 11 -19 0 -73 6 -120 15 -191 33 -656 58 -850 45z"/>
<path d="M5061 8692 c-16 -11 -26 -68 -36 -204 l-7 -97 33 -20 c26 -16 51 -21
102 -21 l68 0 15 35 c8 19 19 35 25 35 6 0 26 -17 45 -39 33 -36 40 -39 109
-46 87 -8 122 4 105 36 -6 11 -40 51 -76 88 -35 38 -64 78 -64 89 0 17 21 39
65 70 6 4 19 17 30 30 16 20 17 25 5 32 -33 21 -72 10 -130 -36 -67 -53 -93
-55 -98 -7 -2 17 -9 36 -16 42 -17 15 -157 25 -175 13z"/>
<path d="M4275 8619 c-33 -4 -85 -16 -115 -26 -55 -18 -55 -18 -54 -58 2 -61
32 -205 52 -244 17 -36 17 -36 74 -33 91 5 112 14 123 50 11 37 23 40 58 12
25 -20 69 -24 116 -11 37 10 36 27 -1 61 l-33 31 18 30 c27 47 22 91 -16 140
-44 59 -84 67 -222 48z m122 -101 c28 -26 29 -42 3 -68 -28 -28 -47 -25 -66
11 -33 65 11 106 63 57z"/>
<path d="M6220 8513 c-8 -3 -46 -33 -85 -67 -38 -34 -96 -83 -127 -109 -41
-35 -58 -56 -58 -73 0 -24 11 -29 54 -25 11 1 28 9 38 18 13 12 29 14 65 9 58
-8 92 -29 105 -67 12 -36 61 -63 146 -80 78 -16 90 -9 74 53 -6 24 -20 86 -32
138 -35 152 -39 164 -64 182 -26 19 -92 31 -116 21z m-32 -140 c9 -17 -1 -63
-13 -63 -17 0 -45 23 -45 37 0 15 27 42 42 43 4 0 11 -8 16 -17z"/>
<path d="M3350 8321 c-66 -36 -68 -39 -76 -228 -6 -157 -5 -173 10 -179 32
-12 65 12 72 54 5 32 13 42 50 61 43 23 64 24 134 6 19 -5 48 4 103 30 42 20
79 41 82 46 12 19 -6 45 -47 68 -24 13 -74 45 -113 70 -154 101 -157 102 -215
72z m46 -187 c20 -30 9 -47 -27 -42 -27 3 -37 32 -19 53 17 21 27 19 46 -11z"/>
<path d="M6980 8138 c-29 -31 -34 -61 -20 -111 23 -82 23 -86 6 -93 -10 -4
-29 6 -51 25 -30 26 -39 29 -57 20 -34 -18 -18 -75 34 -122 112 -101 275 -125
296 -43 10 38 0 89 -22 111 -18 18 -22 70 -6 80 5 3 26 -6 46 -22 l36 -27 19
23 c15 19 17 27 7 44 -18 35 -48 61 -116 100 -77 45 -139 50 -172 15z"/>
<path d="M2581 7836 c-45 -30 -124 -95 -198 -162 -13 -12 -23 -29 -23 -39 0
-33 191 -245 221 -245 10 0 131 92 167 127 8 7 29 25 48 39 106 81 97 191 -18
219 -36 8 -43 14 -46 38 -2 18 -13 32 -32 42 -43 22 -62 19 -119 -19z m24
-111 c0 -13 -8 -21 -24 -23 -32 -5 -48 12 -31 33 18 22 55 15 55 -10z m119
-90 c13 -53 -28 -83 -66 -48 -35 31 -16 73 33 73 20 0 28 -6 33 -25z"/>
<path d="M7674 7641 c-91 -67 -134 -131 -134 -201 0 -88 73 -178 166 -205 81
-23 130 -5 225 84 61 58 71 72 66 93 -8 31 -50 54 -76 42 -11 -5 -42 -30 -69
-56 -63 -61 -91 -72 -121 -49 -33 25 -22 49 54 116 47 41 65 64 65 81 0 30
-90 124 -118 124 -10 0 -37 -13 -58 -29z"/>
<path d="M1856 7065 c-20 -19 -45 -54 -57 -76 -19 -39 -19 -42 -3 -60 9 -10
40 -33 68 -51 28 -18 53 -35 56 -38 5 -7 73 -49 105 -66 20 -10 75 0 75 15 0
7 3 12 61 86 17 22 51 70 76 108 41 60 45 70 35 92 -22 47 -48 33 -121 -63
-30 -40 -51 -40 -108 -2 -24 17 -48 30 -54 30 -5 0 -9 4 -9 8 0 7 -20 19 -82
50 -3 2 -22 -13 -42 -33z"/>
<path d="M8270 6967 c-14 -6 -31 -17 -38 -24 -7 -7 -18 -13 -23 -13 -6 0 -46
-21 -89 -46 -64 -36 -80 -50 -80 -69 0 -29 106 -215 155 -272 34 -40 40 -43
85 -43 65 0 78 6 100 51 16 30 27 39 54 44 47 9 76 39 76 78 0 36 -17 72 -73
160 -20 31 -37 60 -37 63 0 3 -14 24 -31 45 -32 41 -54 47 -99 26z m128 -206
c5 -35 -11 -44 -36 -21 -27 25 -28 37 -4 43 27 7 37 2 40 -22z m-113 -81 c0
-31 -3 -35 -28 -38 -33 -4 -57 13 -57 39 0 23 27 40 60 36 21 -2 25 -7 25 -37z"/>
<path d="M5193 6712 c-6 -4 -66 -59 -132 -121 -114 -108 -121 -117 -121 -153
0 -41 2 -43 95 -125 33 -28 75 -65 94 -82 l34 -30 8 -248 c20 -616 28 -978 31
-1364 l3 -416 -103 -99 c-71 -69 -102 -106 -102 -122 0 -34 24 -75 61 -102 18
-14 51 -41 73 -61 84 -75 150 -129 158 -129 28 0 97 52 196 149 98 97 112 114
112 144 0 34 -3 37 -178 194 l-44 39 -8 134 c-5 74 -14 380 -19 680 -6 300
-16 688 -22 863 -13 370 -23 327 112 457 120 114 123 130 42 208 -71 68 -166
150 -202 174 -27 19 -68 23 -88 10z"/>
<path d="M6695 6650 c-114 -12 -319 -70 -349 -100 -5 -5 -16 -10 -24 -10 -12
0 -131 -75 -142 -90 -3 -4 -12 -11 -22 -16 -34 -19 -138 -160 -162 -219 -36
-89 -49 -228 -31 -328 7 -43 19 -84 25 -92 15 -18 3 -24 -69 -29 -73 -5 -86
-14 -100 -69 -12 -52 -38 -131 -57 -174 -8 -17 -14 -37 -14 -45 0 -7 -7 -26
-15 -42 -9 -16 -20 -46 -25 -65 -4 -20 -36 -115 -70 -211 -90 -256 -91 -262
-47 -335 10 -16 93 -157 127 -215 5 -8 34 -58 64 -110 86 -149 69 -140 259
-140 89 0 172 3 183 6 12 3 45 35 74 70 43 54 55 64 73 58 12 -4 24 -10 27
-14 19 -26 266 -113 410 -144 212 -44 256 -49 440 -49 287 0 506 49 648 144
24 16 46 29 50 29 21 1 162 159 162 182 0 6 6 19 13 27 8 9 25 50 38 91 20 63
23 96 23 205 0 135 -12 187 -66 294 -10 19 -18 45 -18 58 0 24 54 196 70 225
6 10 10 25 10 34 0 8 8 36 18 62 27 68 42 111 58 162 25 85 46 145 54 161 5 9
14 36 20 59 11 41 10 45 -21 89 -18 25 -37 48 -41 49 -4 2 -8 8 -8 14 0 6 -10
23 -22 38 -13 15 -30 37 -38 49 -16 24 -82 114 -157 214 -25 32 -47 65 -49 72
-11 29 -62 37 -203 33 l-140 -3 -56 -55 -56 -55 -42 24 c-113 66 -210 104
-317 126 -36 8 -83 18 -105 23 -58 15 -286 21 -380 12z m390 -129 c17 -5 64
-20 105 -32 132 -37 229 -90 329 -178 13 -11 26 -21 29 -21 3 0 38 35 78 78
l73 77 88 0 88 0 -1 -30 c-1 -16 -18 -77 -39 -135 -21 -58 -46 -127 -56 -155
-10 -27 -30 -81 -44 -120 -14 -38 -30 -84 -36 -101 -11 -36 -29 -44 -106 -44
-80 0 -99 11 -107 62 -23 150 -134 304 -268 371 -150 74 -314 73 -390 -2 -51
-52 -57 -84 -27 -139 28 -50 119 -112 164 -112 8 0 16 -4 19 -9 3 -5 30 -15
58 -21 29 -7 58 -16 63 -20 6 -4 28 -12 50 -18 171 -45 366 -165 437 -267 49
-73 68 -138 68 -239 0 -217 -110 -393 -302 -484 -156 -75 -245 -92 -474 -92
-179 0 -289 13 -394 47 -25 8 -56 17 -70 20 -24 5 -70 24 -150 61 -19 9 -65
34 -101 55 -36 20 -73 35 -81 32 -8 -3 -43 -37 -77 -75 l-62 -70 -115 0 c-112
0 -114 0 -114 23 0 13 4 27 9 33 8 8 44 114 62 179 4 17 21 68 38 115 17 47
31 92 31 100 0 8 9 33 20 55 11 22 19 47 20 56 0 9 11 42 25 73 l25 56 102 0
c119 0 122 -2 134 -91 7 -55 44 -170 61 -191 6 -7 14 -24 18 -36 4 -13 34 -51
67 -84 82 -82 161 -119 271 -125 108 -7 156 7 212 61 43 41 45 45 45 102 0
113 -68 168 -330 269 -201 78 -260 108 -317 163 -67 63 -88 103 -104 191 -39
225 99 456 331 550 30 12 64 26 75 30 11 5 58 17 105 26 47 10 90 19 95 21 22
8 342 -5 370 -15z"/>
<path d="M4200 6563 c-909 -17 -985 -24 -1175 -115 -33 -15 -68 -34 -79 -41
-65 -43 -160 -136 -201 -197 -16 -25 -33 -47 -37 -50 -9 -7 -128 -258 -128
-270 0 -5 -9 -29 -19 -52 -11 -24 -38 -88 -59 -143 -34 -85 -93 -212 -139
-299 -7 -13 -30 -43 -52 -67 -37 -42 -40 -43 -64 -30 -14 7 -28 24 -31 37 -8
31 -108 130 -160 157 -165 86 -390 55 -524 -73 -53 -50 -75 -81 -111 -155 -22
-45 -24 -64 -25 -175 -1 -115 1 -131 27 -193 24 -58 85 -171 109 -202 172
-223 333 -372 458 -426 50 -22 69 -24 205 -24 138 1 156 3 228 28 43 16 90 33
105 40 44 20 164 102 212 146 25 22 54 41 65 41 10 0 42 -24 70 -53 27 -29 60
-56 71 -59 12 -3 403 -1 869 4 l847 9 19 22 c10 12 24 40 30 62 6 22 17 54 24
71 8 18 17 49 21 70 4 20 13 49 20 63 17 33 18 85 2 103 -16 16 -58 76 -58 81
0 2 -34 55 -75 117 -80 122 -83 133 -49 198 8 15 14 35 14 45 0 10 5 28 11 40
7 11 18 42 25 67 11 40 97 297 125 375 6 17 15 46 20 65 6 19 17 49 24 66 8
16 17 49 21 71 5 35 1 50 -27 100 -18 32 -36 60 -39 63 -4 3 -20 28 -36 56
-36 64 -104 178 -124 209 -20 31 -92 151 -108 182 -18 31 -51 44 -115 41 -29
-1 -113 -4 -187 -5z m109 -137 c9 -11 3 -41 -29 -136 -22 -67 -40 -127 -40
-133 0 -7 -7 -25 -15 -41 -9 -16 -20 -46 -25 -65 -10 -39 -34 -114 -54 -171
-8 -19 -23 -69 -36 -110 -12 -41 -28 -89 -35 -105 -7 -17 -16 -46 -20 -65 -4
-19 -11 -39 -15 -45 -5 -5 -13 -30 -20 -54 -6 -24 -16 -55 -22 -70 -12 -28
-27 -74 -52 -160 -23 -80 -19 -83 138 -81 72 1 137 1 144 1 20 -1 14 -73 -12
-142 -22 -60 -26 -64 -62 -72 -22 -4 -336 -9 -699 -10 l-660 -2 3 30 c3 34 40
143 55 163 7 8 44 12 122 12 l113 0 21 34 c12 18 24 49 28 67 3 19 12 43 18
54 7 11 16 36 19 56 4 21 13 51 22 68 8 17 14 41 12 53 -3 22 -7 23 -128 26
-69 1 -151 -1 -184 -5 -66 -9 -57 2 -138 -168 -24 -49 -49 -96 -55 -103 -7 -7
-13 -18 -13 -23 0 -6 -21 -42 -47 -81 -27 -39 -53 -78 -58 -88 -19 -31 -99
-111 -156 -156 -72 -55 -176 -108 -259 -130 -96 -26 -316 -30 -383 -8 -109 35
-179 94 -220 184 -17 36 -21 64 -21 140 1 82 4 102 27 146 108 211 434 225
511 22 17 -42 16 -46 -3 -96 -16 -39 -18 -58 -11 -77 9 -23 15 -25 71 -25 58
0 120 21 168 55 37 28 108 107 146 165 48 75 125 208 125 219 0 4 18 44 40 89
22 44 40 87 40 94 0 7 7 22 15 32 8 11 15 27 15 35 1 9 9 34 20 56 11 22 19
44 20 48 0 9 7 27 55 132 62 136 179 274 279 330 72 40 176 77 266 96 43 9
183 15 410 19 190 3 393 7 451 8 81 2 109 -1 118 -12z"/>
<path d="M3270 6183 c-58 -19 -123 -57 -163 -95 -26 -24 -47 -47 -47 -51 0 -4
-15 -29 -33 -55 -34 -50 -61 -103 -83 -164 -12 -35 -11 -39 6 -48 28 -15 299
-13 330 3 17 9 29 26 37 57 6 25 18 65 27 90 48 135 82 247 78 258 -4 12 -120
16 -152 5z"/>
<path d="M711 6478 c-9 -26 -41 -128 -65 -208 -47 -161 -56 -194 -56 -215 0
-12 -6 -45 -14 -71 -23 -79 -55 -253 -61 -334 -4 -41 -13 -115 -21 -165 -23
-147 -19 -683 6 -905 11 -96 27 -206 35 -245 8 -38 17 -90 20 -115 4 -25 11
-61 17 -80 6 -19 17 -64 24 -100 20 -105 73 -286 125 -425 11 -28 19 -58 19
-67 0 -9 4 -20 10 -23 5 -3 16 -27 24 -53 25 -83 48 -136 152 -347 44 -88 85
-166 91 -173 7 -7 13 -17 13 -22 0 -9 54 -106 95 -170 35 -56 179 -274 187
-285 5 -5 45 -57 89 -115 45 -58 87 -109 93 -115 6 -5 39 -44 73 -85 143 -174
465 -478 604 -570 12 -8 31 -23 41 -33 10 -10 44 -34 76 -54 31 -19 62 -41 67
-47 6 -6 37 -29 70 -52 33 -22 84 -57 112 -77 29 -20 57 -37 62 -37 6 0 14 -6
18 -13 4 -7 19 -18 33 -25 14 -6 32 -16 40 -22 17 -11 177 -102 200 -114 8 -4
33 -18 55 -31 42 -26 52 -31 155 -77 36 -16 84 -38 108 -49 146 -67 200 -89
213 -89 6 0 25 -7 42 -15 36 -16 71 -29 237 -85 63 -21 131 -42 150 -45 19 -4
40 -11 45 -15 6 -4 26 -11 45 -14 19 -4 55 -14 80 -21 25 -8 95 -23 155 -35
61 -12 142 -28 180 -36 39 -8 108 -19 155 -24 47 -5 119 -14 160 -19 41 -5
163 -14 270 -21 107 -6 204 -13 215 -15 19 -5 302 14 555 35 155 13 342 36
415 50 164 32 255 52 300 65 28 7 68 18 90 24 78 19 223 65 273 87 18 8 40 14
48 14 9 0 28 6 42 14 15 7 54 24 87 36 83 30 155 59 190 75 227 104 270 125
390 190 55 29 114 61 130 69 64 34 137 85 151 105 14 19 13 22 -10 32 -25 12
-51 -1 -214 -103 -15 -9 -73 -40 -130 -68 -56 -29 -105 -56 -108 -61 -3 -5
-12 -9 -20 -9 -7 0 -49 -16 -91 -36 -142 -66 -160 -74 -171 -74 -6 0 -23 -6
-39 -14 -66 -32 -125 -56 -141 -56 -9 0 -26 -7 -36 -15 -11 -8 -28 -15 -39
-15 -10 0 -33 -7 -50 -15 -18 -8 -50 -19 -72 -24 -22 -5 -53 -14 -70 -20 -27
-9 -73 -22 -225 -64 -30 -8 -100 -25 -155 -36 -55 -12 -131 -28 -170 -36 -38
-9 -83 -15 -100 -15 -16 0 -59 -7 -95 -16 -36 -8 -128 -20 -205 -25 -77 -5
-178 -13 -225 -18 -108 -12 -405 -13 -535 -1 -55 5 -151 13 -213 19 -128 11
-287 35 -379 56 -34 8 -71 15 -83 15 -24 0 -153 29 -270 60 -41 12 -102 28
-135 37 -81 21 -189 56 -271 88 -38 14 -94 35 -124 46 -30 11 -68 26 -85 34
-16 8 -57 26 -90 40 -160 71 -298 139 -390 191 -22 12 -65 35 -95 51 -30 16
-75 42 -100 58 -25 16 -53 34 -62 40 -18 11 -216 144 -233 157 -98 74 -251
194 -255 199 -3 3 -36 32 -75 64 -128 105 -423 405 -526 535 -135 172 -271
362 -355 495 -24 39 -44 76 -44 83 0 6 -4 12 -9 12 -5 0 -16 17 -26 38 -22 50
-47 96 -55 102 -3 3 -17 28 -29 55 -12 28 -27 59 -33 70 -16 29 -23 44 -47 97
-72 157 -83 185 -111 263 -12 33 -28 74 -34 90 -21 49 -47 128 -56 165 -5 19
-15 55 -24 80 -20 60 -39 135 -65 250 -11 52 -27 127 -36 166 -8 39 -20 110
-26 159 -5 48 -14 106 -19 129 -5 23 -14 147 -20 276 -12 267 1 671 25 780 8
36 15 85 15 110 0 25 5 63 10 85 11 44 44 194 65 295 20 97 35 150 56 198 10
23 19 50 19 60 0 9 9 40 20 69 11 29 17 59 14 68 -9 24 -53 19 -63 -7z"/>
<path d="M1775 6389 c-16 -5 -64 -14 -105 -19 -74 -10 -134 -23 -197 -42 -23
-7 -37 -21 -52 -52 -12 -23 -21 -47 -21 -53 0 -16 63 -98 145 -189 94 -104 94
-104 114 -104 41 0 57 61 21 80 -28 15 -26 62 8 121 19 34 33 49 48 49 11 0
28 6 37 13 24 17 60 114 61 162 1 44 -9 50 -59 34z m-160 -239 c9 -27 -1 -40
-31 -40 -26 0 -40 20 -30 45 8 23 53 19 61 -5z"/>
<path d="M1117 4384 c-17 -17 18 -109 49 -129 30 -20 32 -60 3 -76 -19 -10
-21 -17 -15 -63 8 -69 24 -80 84 -54 26 11 63 26 82 35 59 25 103 45 148 67
47 23 50 36 31 126 -12 58 -28 70 -91 70 -24 0 -75 7 -113 15 -73 15 -167 20
-178 9z m206 -151 c-7 -2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z"/>
<path d="M8387 4004 c-10 -21 -21 -59 -24 -84 -5 -46 -5 -47 33 -70 22 -13 51
-35 67 -48 35 -33 22 -42 -61 -42 -80 0 -100 -11 -107 -58 -3 -20 -12 -51 -20
-69 -11 -24 -11 -34 -2 -41 16 -13 230 -76 299 -88 41 -7 47 0 62 71 6 28 3
31 -46 55 -75 36 -74 50 4 50 60 0 65 2 81 29 28 47 22 65 -33 100 -67 43 -65
55 9 48 70 -8 84 3 85 65 1 41 0 42 -44 54 -25 7 -81 22 -125 35 -44 12 -98
24 -120 26 -39 4 -41 3 -58 -33z"/>
<path d="M1555 3688 c-16 -5 -39 -13 -50 -18 -11 -5 -36 -14 -55 -19 -111 -32
-145 -48 -148 -71 -2 -13 1 -32 7 -44 6 -12 22 -52 35 -91 14 -38 31 -74 39
-79 10 -6 30 -5 56 3 47 14 50 27 24 92 -16 41 -16 42 8 60 13 10 40 21 59 24
19 4 40 10 45 15 6 5 26 12 45 17 31 8 35 13 38 46 2 26 -3 42 -17 56 -23 23
-40 25 -86 9z"/>
<path d="M8114 3163 c-69 -9 -154 -119 -154 -199 0 -76 63 -149 142 -166 79
-17 136 1 188 59 43 49 55 88 48 162 -10 102 -101 160 -224 144z m71 -139 c43
-28 23 -104 -28 -104 -53 0 -77 75 -35 104 28 20 33 20 63 0z"/>
<path d="M1761 3039 c-172 -100 -169 -97 -146 -146 10 -21 21 -40 24 -43 3 -3
19 -24 34 -48 29 -44 99 -102 125 -102 34 0 80 40 86 75 7 34 9 35 51 35 46 0
70 16 86 56 4 13 6 32 3 41 -15 46 -68 142 -97 174 l-32 36 -134 -78z m144
-100 c10 -15 -1 -23 -20 -15 -9 3 -13 10 -10 16 8 13 22 13 30 -1z m-115 -74
c0 -9 -9 -15 -25 -15 -24 0 -32 10 -18 23 12 13 43 7 43 -8z"/>
<path d="M7609 2537 c-20 -12 -50 -37 -65 -56 -26 -31 -29 -42 -29 -106 0 -57
4 -76 22 -98 43 -55 73 -71 139 -75 85 -6 141 21 183 87 48 75 41 100 -36 114
-27 5 -35 3 -44 -16 -19 -37 -51 -67 -72 -67 -25 0 -67 45 -67 70 0 11 16 32
35 47 35 28 42 51 29 101 -8 29 -47 29 -95 -1z"/>
<path d="M2300 2449 c-36 -17 -72 -34 -80 -39 -8 -6 -48 -25 -87 -45 -85 -41
-91 -57 -38 -104 25 -21 46 -31 69 -31 41 0 60 -21 52 -55 -5 -21 2 -34 39
-73 l45 -47 26 30 c14 17 30 39 35 50 18 35 44 79 81 136 57 89 57 91 -5 154
-30 30 -59 55 -63 54 -5 0 -38 -14 -74 -30z"/>
<path d="M2843 1993 c-59 -72 -153 -211 -153 -228 0 -8 20 -26 46 -41 l45 -27
23 22 c34 32 46 27 46 -22 0 -31 6 -47 21 -61 28 -24 87 -48 99 -41 6 4 10 31
10 61 0 52 2 55 39 82 50 35 81 80 81 117 0 23 -14 42 -61 87 -33 32 -65 58
-71 58 -6 0 -19 6 -27 14 -38 33 -57 29 -98 -21z m113 -114 c7 -12 -15 -31
-28 -23 -4 3 -8 12 -8 20 0 17 26 19 36 3z"/>
<path d="M7242 1872 c-31 -24 -26 -61 10 -81 25 -15 29 -14 52 8 32 30 32 42
3 69 -28 26 -35 27 -65 4z"/>
<path d="M6580 1770 c-8 -5 -45 -23 -82 -39 -75 -34 -95 -66 -64 -100 9 -10
16 -25 16 -34 0 -9 4 -18 10 -22 5 -3 23 -34 40 -68 60 -117 70 -129 98 -122
28 7 130 53 147 67 62 49 74 62 80 87 10 46 -10 80 -61 102 -34 15 -43 24 -35
32 19 19 12 52 -17 80 -22 21 -37 27 -73 26 -24 0 -51 -4 -59 -9z m35 -110 c4
-6 -5 -10 -20 -10 -15 0 -24 4 -20 10 3 6 12 10 20 10 8 0 17 -4 20 -10z m62
-115 c5 -16 -14 -38 -26 -31 -18 12 -13 48 6 44 9 -2 18 -7 20 -13z"/>
<path d="M3493 1633 c-11 -27 -33 -77 -48 -113 -14 -36 -33 -80 -42 -99 -19
-42 -11 -59 41 -85 l37 -18 30 28 c16 15 31 36 35 46 10 31 24 9 24 -38 0 -54
30 -88 87 -99 47 -9 56 2 44 56 -6 24 -11 63 -11 86 0 39 4 45 58 86 93 72 93
72 29 102 -31 14 -60 25 -65 25 -5 0 -25 -13 -43 -30 -19 -16 -38 -30 -42 -30
-5 0 -7 19 -5 42 3 40 1 43 -37 65 -22 12 -47 23 -55 23 -8 0 -25 -21 -37 -47z"/>
<path d="M5941 1525 c-49 -17 -55 -35 -36 -103 30 -103 30 -117 1 -135 -24
-16 -26 -16 -49 7 -15 15 -28 45 -36 84 -17 85 -34 103 -89 93 -60 -12 -67
-21 -54 -68 6 -21 19 -65 28 -98 18 -66 38 -96 86 -132 60 -44 172 -26 231 38
42 45 40 165 -4 277 -23 55 -25 56 -78 37z"/>
<path d="M4307 1432 c-9 -10 -23 -33 -32 -52 -8 -19 -19 -42 -24 -50 -4 -8
-14 -26 -21 -40 -7 -13 -23 -44 -36 -68 -13 -24 -25 -56 -26 -71 -3 -24 1 -27
50 -35 47 -8 56 -7 83 13 38 29 71 22 85 -18 11 -27 16 -29 69 -33 31 -1 62 0
68 4 8 5 6 35 -6 110 -10 57 -17 122 -17 145 0 72 -12 91 -67 98 -26 3 -62 8
-79 11 -21 3 -36 -1 -47 -14z m73 -182 c0 -19 -11 -26 -23 -14 -8 8 3 34 14
34 5 0 9 -9 9 -20z"/>
<path d="M5065 1391 c-30 -13 -72 -59 -79 -87 -10 -37 21 -83 74 -111 60 -32
61 -43 6 -43 -34 0 -50 -6 -66 -22 -26 -29 -26 -33 12 -59 23 -17 50 -24 95
-27 53 -4 69 -1 107 22 93 54 77 165 -29 194 -40 11 -38 26 5 30 33 3 69 33
70 57 0 6 -15 20 -33 33 -35 23 -120 30 -162 13z"/>
</g>
</svg>

		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
